<template>
  <div class="rule">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="el-nav">
      <el-tab-pane label="写文章 " name="create">
        <el-form label-position="right" label-width="130px" class="addForm" :model="form" :rules="rules" ref="form">
          <el-form-item label="封面图" prop="cover_picture">
            <upload-file @successUrl="imgUrl" :limit="1" :folder="'discover'" :url="url"
                         :default_value="new Array(form.cover_picture ? form.cover_picture : '')"></upload-file>
          </el-form-item>
          <el-form-item label="摘要" prop="summary">
            <el-input class="width-input" v-model="form.summary" maxlength="200" show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="标题" prop="title">
            <el-input class="register-input" v-model="form.title" type="textarea" :rows="3" maxlength="50"
                      show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="正文" prop="content" class="ueditor-line-height">
            <div style="padding-right: 6px">
              <ueditor v-model="form.content" placeholder="请输入课程介绍" :folder="'discover'"></ueditor>
            </div>
            <!--            <el-input class="register-input" v-model="form.content"  type="textarea" :rows="3" ></el-input>-->
          </el-form-item>
          <el-form-item label="创作者" prop="author_user_id" v-if="is_admin_middleground === 'true'">
            <el-select class="authorselect" v-model="form.author_user_id" clearable filterable
                       placeholder="请搜索或选择创作者">
              <el-option v-for="item in authorsList" :key="item.id" :label="`${item.nickname}-${item.mobile}`"
                         :value="item.id">
                <div @click="onAuthorOption(item)">{{ item.nickname }}-{{ item.mobile }}</div>
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="原创声明" prop="create_type">
            <el-radio v-model="form.create_type" :label="1">原创</el-radio>
            <el-radio v-model="form.create_type" :label="2">转载</el-radio>
          </el-form-item>
          <el-form-item label="来源" prop="source" v-if="form.create_type == 2">
            <el-input class="width-input" v-model="form.source" maxlength="50" show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="金币规则">
            <el-select v-model="form.rule_item_id" placeholder="请选择" clearable style="width: 210px">
              <el-option v-for="item in ruleoptions" :key="item.id"
                         :label="item.title + '(' + item.details.val + '金币' + ')'" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="标签">
            <el-tag v-for="tag in form.tags" :key="tag.name" closable :disable-transitions="false"
                    @close="handleClose(tag)">
              {{ tag.name }}
            </el-tag>
            <el-select class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
                       @change="handleInputConfirm">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"
                         :disabled="item.disabled"></el-option>
            </el-select>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</el-button>
          </el-form-item>
          <el-form-item label="同步到平台">
            <el-radio v-model="form.is_square" :label="1">是</el-radio>
            <el-radio v-model="form.is_square" :label="0">否</el-radio>
          </el-form-item>
        </el-form>
        <div class="form-operation align-center">
          <el-button class="form-operation-item" round @click="$router.go(-1)">取消</el-button>
          <el-button class="form-operation-item" type="primary" round @click="sub(true), (preview_tye = null)"
                     v-if="isAdd || this.form.publish_status === 0">发布
          </el-button>
          <el-button class="form-operation-item draft" round v-if="isAdd" @click="sub(), (preview_tye = null)">存草稿
          </el-button>
          <el-button class="form-operation-item" type="primary" round @click="sub(), (preview_tye = null)" v-else
                     v-text="this.form.publish_status === 0 ? '保存' : '更新'"></el-button>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div class="preview">
      <el-dropdown @command="handlePreview">
        <span class="el-dropdown-link">
          <div class="eye vertical-center">
            <img src="~assets/images/data/eye.png" alt=""/>
            &nbsp&nbsp预览
          </div>
          <div class="hover vertical-center">
            <img src="~assets/images/data/eye-hover.png" alt=""/>
            &nbsp&nbsp预览
          </div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="local">本地预览</el-dropdown-item>
          <el-dropdown-item command="url">真机预览</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog :title="form.title" :visible.sync="dialogVisible" width="458px">
      <div class="model-mobile-box">
        <img src="~assets/images/public/model-mobile-bg.png" class="model-mobile-bg" alt=""/>
        <div class="dialog-content" v-html="form.content"
             style="font-size: 12px; font-weight: 400; color: #71777d; max-height: 550px; overflow-y: auto"></div>
      </div>
    </el-dialog>
    <!--     预览显示开始-->
    <el-dialog :visible.sync="dialogUrlPreview" width="300px" custom-class="preview-dialog" v-if="dialogUrlPreview">
      <div class="preview-code">
        <div id="qrcode"></div>
        <p class="hint-p">手机扫一扫, 查看文章预览</p>
      </div>
    </el-dialog>
    <!-- 预览显示结束   -->

    <!--引导认证-->
    <SpringFrame
        :authentication="authentication"
        :bankCark="bankCark"
        @handleCloseAuthenticationBank="handleCloseAuthenticationBank"
        @handleCloseAuthentication="handleCloseAuthentication"
        @goBankPage="goBankPage"
    ></SpringFrame>
  </div>
</template>

<script>
import uploadFile from 'components/uploadFile';
import SpringFrame from 'components/SpringFrame';
import _ from 'lodash';
import QRCode from 'qrcodejs2';

export default {
  name: 'Create',
  components: {uploadFile, SpringFrame},
  data() {
    return {
      authentication: false,
      bankCark: false,
      activeName: 'create',
      dialogVisible: false,
      isAdd: true,
      url: 'image/browse',
      approval: 2, // 职业认证
      bind_bank: 'false', // 绑定银行卡
      // is_circle_author: 'false', // 创作者
      is_admin_middleground: 'false', // 管理员
      is_has_benefit_circle: 'false', // 是否参与收益频道
      rules: {
        cover_picture: [{required: true, message: '请上传封面图', trigger: 'blur'}],
        title: [{required: true, message: '请填写标题', trigger: 'blur'}],
        content: [{required: true, message: '请填写正文', trigger: 'blur'}],
        create_type: [{required: true, message: '请选择', trigger: 'blur'}],
        source: [{required: true, message: '请填写来源', trigger: 'blur'}],
        author_user_id: [{required: true, message: '请选择作者', trigger: 'change'}],
      },
      form: {
        create_type: 1,
        title: '',
        cover_picture: '',
        content: '',
        tags: [],
        author_user_id: '',
        is_square: 0,
      },
      inputVisible: false,
      inputValue: '',
      options: [],
      ruleoptions: [],
      value: '',
      preview_tye: null, // 阅览方式
      dialogUrlPreview: false, // url预览
      uuid: '',
      authorsList: [], // 作者列表
      circle_uuid: '',
    };
  },
  created() {
    this.getTags();
    this.getRule();
    this.getAuthorsList();
    if (this.$route.query.id !== undefined) {
      this.getInfo();
    }
    this.approval = Number(localStorage.getItem('is_approval'));
    this.bind_bank = localStorage.getItem('is_bind_bank');
    // this.is_circle_author = localStorage.getItem('is_circle_author');
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    this.is_has_benefit_circle = localStorage.getItem('is_has_benefit_circle');
    if (this.approval !== 1 && this.is_admin_middleground === 'false') {
      this.authentication = true;
    } else if (this.approval === 1 && this.bind_bank === 'false' && this.is_admin_middleground === 'false' && this.is_has_benefit_circle === 'true') {
      this.bankCark = true;
    }
  },

  methods: {
    // 选择创作者
    onAuthorOption(item) {
      this.form.author = item.nickname;
    },
    // 取消弹框
    handleCloseAuthentication() {
      this.$router.go(-1);
    },
    // 取消弹框
    handleCloseAuthenticationBank() {
      this.bankCark = false;
    },
    // 绑定银行卡
    goBankPage() {
      this.$router.push('/profile?activeName=third');
    },
    getAuthorsList() {
      let url = this.$api.authors;
      this.$http.get(url, true).then((res) => {
        console.log(res.data);
        if (res.data.success) {
          this.authorsList = res.data.data;
        }
      });
    },
    //获取规则
    getRule() {
      let url = this.$api.ruleItems + `?code=article`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.ruleoptions = res.data.data;
        }
      });
    },
    //获取标签
    getTags() {
      let url = this.$api.tagsCircle;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.options = res.data.data;
        }
      });
    },
    //获取信息
    getInfo() {
      this.isAdd = false;
      let url = this.$api.DiscoverContents + `/` + this.$route.query.id;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.form = res.data.data;

          let that = this;
          this.form.tags.forEach(function (item, index) {
            that.options.forEach(function (tag, num) {
              if (tag.id === item.id) {
                tag.disabled = true;
              }
            });
          });
        }
      });
    },
    //删除标签
    handleClose(tag) {
      this.form.tags.splice(this.form.tags.indexOf(tag), 1);
      this.options.forEach(function (item, index) {
        if (item.id === tag.id) {
          item.disabled = false;
        }
      });
    },
    //新增标签
    showInput() {
      this.inputVisible = true;
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      let as = '';
      if (inputValue) {
        this.options.forEach(function (item, index) {
          if (item.id === inputValue) {
            as = index;
          }
        });
        this.form.tags.push(this.options[as]);
        this.options[as].disabled = true;
        this.form.tags = this.form.tags.reduce((cur, next) => {
          this.form.tags[next.id] ? '' : (this.form.tags[next.id] = true && cur.push(next));
          return cur;
        }, []);
        // this.options.splice(as,1)
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    //新增修改草稿
    sub: _.debounce(function (val) {
      console.log(this.is_admin_middleground, 'llllllllllllllllllllll');
      if (this.form.tags.length > 8) {
        this.$message.error('最多选择8个标签');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            //新增
            if (val) {
              let url = this.$api.DiscoverContents;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 0;
              data.type = 'article';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              this.$http
                  .post(url, data, true)
                  .then((res) => {
                    if (res.data.success) {
                      this.$message.success('新增成功');
                      // this.form = res.data.data
                      this.$router.push({
                        path: '/creation',
                        query: {
                          artivleType: 'two',
                        },
                      });
                    } else {
                      this.$message.error(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.$message.error(error.response.data.message);
                  });
            }
            //草稿
            else {
              let url = this.$api.DiscoverContents;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 1;
              data.type = 'article';
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              if (this.preview_tye != null) {
                data.preview = 1;
              }
              this.$http
                  .post(url, data, true)
                  .then((res) => {
                    if (res.data.success) {
                      if (this.preview_tye != null) {
                        if (this.preview_tye == 'local') {
                          this.form.uuid = res.data.data.uuid;
                          this.dialogVisible = true;
                        } else {
                          this.form.uuid = res.data.data.uuid;
                          this.dialogUrlPreview = true;
                          setTimeout(() => {
                            this.Qrcode();
                          }, 200);
                        }
                        this.form.publish_status = 0;
                        this.isAdd = false;
                        return false;
                      }
                      this.$message.success('存入草稿');
                      this.$router.push({
                        path: '/creation',
                        query: {
                          artivleType: 'four',
                        },
                      });
                    } else {
                      this.$message.error(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.$message.error(error.response.data.message);
                  });
            }
          }
          //修改
          else {
            if (val) {
              let url = this.$api.DiscoverContents + `/` + this.form.uuid;
              let data = JSON.parse(JSON.stringify(this.form));
              data.is_draft = 0;
              if (this.preview_tye != null) {
                data.preview = 1;
              }
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              data.type = 'article';
              this.$http
                  .put(url, data, true)
                  .then((res) => {
                    if (res.data.success) {
                      if (this.preview_tye != null) {
                        if (this.preview_tye == 'local') {
                          this.dialogVisible = true;
                        } else {
                          this.dialogUrlPreview = true;
                          setTimeout(() => {
                            this.Qrcode();
                          }, 200);
                        }
                        return false;
                      }
                      this.$message.success('修改成功');
                      // this.form =res.data.data
                      this.$router.push({
                        path: '/creation',
                        query: {
                          artivleType: 'two',
                        },
                      });
                    } else {
                      this.$message.error(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.$message.error(error.response.data.message);
                  });
            } else {
              console.log(this.form, 'zzzzzzzzzzzzz');
              let url = this.$api.DiscoverContents + `/` + this.form.uuid;
              let data = JSON.parse(JSON.stringify(this.form));
              console.log(data.publish_status, '----------------------------');
              if (data.publish_status === 1) {
                data.is_draft = 0;
              } else {
                data.is_draft = 1;
              }
              if (this.preview_tye != null) {
                data.preview = 1;
              }
              data.tags = data.tags.map(function (item) {
                return item['id'];
              });
              data.type = 'article';
              this.$http
                  .put(url, data, true)
                  .then((res) => {
                    if (res.data.success) {
                      if (this.preview_tye != null) {
                        if (this.preview_tye == 'local') {
                          this.dialogVisible = true;
                        } else {
                          this.dialogUrlPreview = true;
                          setTimeout(() => {
                            this.Qrcode();
                          }, 200);
                        }
                        return false;
                      }
                      this.$message.success('修改成功');
                      // this.form =res.data.data
                      // this.$router.push('/article/article')
                      if (this.form.publish_status === 0) {
                        this.$router.push({
                          path: '/creation',
                          query: {
                            artivleType: 'four',
                          },
                        });
                      } else {
                        this.$router.push({
                          path: '/creation',
                          query: {
                            artivleType: 'two',
                          },
                        });
                      }
                    } else {
                      this.$message.error(res.data.message);
                    }
                  })
                  .catch((error) => {
                    this.$message.error(error.response.data.message);
                  });
            }
          }
        } else {
          return false;
        }
      });
    }, 500),
    //上传图片
    imgUrl(data) {
      console.log(data, '11111111111111111111111')
      if (!data[data.length - 1]) {
        data[data.length - 1] = '';
      }
      this.form.cover_picture = data[data.length - 1];
      this.$refs.form.clearValidate('cover_picture');
    },
    // tab切换
    handleClick(tab, event) {
      if (tab.name === 'courses') {
        this.$router.go(-1);
      }
    },
    // 预览
    handlePreview(command) {
      this.preview_tye = command;
      this.sub();
    },
    // 生成二维码方法
    Qrcode() {
      var qrcode = new QRCode('qrcode', {
        text: process.env.VUE_APP_M_LINK + `/article/show?uuid=${this.form.uuid}`, // url
        width: 120,
        height: 120,
        colorDark: '#000000',
        colorLight: '#ffffff',
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/article/text.less';

.model-mobile-box {
  width: 328px;
  height: 667px;
  margin: 0 auto;
  position: relative;

  .model-mobile-bg {
    width: 100%;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }

  .dialog-content {
    width: 320px;
    height: 496px;
    position: absolute;
    left: 50%;
    top: 90px;
    z-index: 1;
    margin-left: -160px;
    word-break: break-word;
    padding: calc(20px + env(safe-area-inset-top)) calc(16px + env(safe-area-inset-right)) 12px calc(16px + env(safe-area-inset-left));
    box-sizing: border-box;
    font-size: 17px;
    hyphens: auto;
    text-align: justify;
  }
}

.preview-code {
  #qrcode {
    width: 120px;
    height: 120px;
    margin: 0 auto;
  }

  .hint-p {
    font-size: 14px;
    color: #606266;
    text-align: center;
    margin-top: 12px;
  }
}
</style>
<style>
.preview-dialog >>> .el-dialog__header {
  padding-top: 32px !important;
}
</style>
